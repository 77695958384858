const greyLinedBar = {
    pattern: {
        path: {
            d: 'M 10 0 L 0 10 M 11 9 L 9 11 M 1 -1 L -1 1',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#8e94a5',
        backgroundColor: '#495B8F'
    }
}

const greenLinedBar = {
    pattern: {
        path: {
            d: 'M 0 0 L 10 10 M 9 -1 L 11 1 M -1 9 L 1 11',
            strokeWidth: 0.5
        },
        width: 10,
        height: 10,
        color: '#ffffff',
        backgroundColor: '#099290'
    }
}

const crosshair = 'M5 0 L5 10 M0 5 L10 5'

const purpleShape = {
    pattern: {
        path: {
            d: crosshair,
            strokeWidth: 1,
        },
        width: 5,
        height: 5,
        backgroundColor: '#8E88EA',
        color: '#2B3963'
    }
}

const solidDarkBlue = '#0028a1'

const solidLightBlue = '#2b87ff'

export const singleSeriesPattern = [solidLightBlue]
export const twoSeriesPatterns = [greyLinedBar, solidLightBlue]
export const fiveSeriesPatterns = [greenLinedBar, purpleShape, solidDarkBlue, greyLinedBar, solidLightBlue]
