<template>
    <div v-if="useNewLaunchPad" class="d-flex flex-fill flex-wrap" style="overflow: scroll;">
        <v-row class="d-flex" style="min-height: 300px;">
            <v-col cols=4>
                <today-card/>
            </v-col>

            <v-col cols=4>
                <intervention-participation-card :launcpadStyle=true />
            </v-col>

            <v-col cols=4>
                <intervention-tools-card/>
            </v-col>
        </v-row>

        <v-row class="d-flex " style="min-height: 300px;">
            <v-col cols=4>
                 <students-card/>
            </v-col>

            <v-col cols=4>
                <incidents-card/>
            </v-col>

            <v-col cols=4>
                <attendance-card/>
            </v-col>
        </v-row>

        <v-row class="d-flex " style="min-height: 300px;" :key="caReloadKey">
            <v-col cols=4 v-if="showChrnAbsenceCard && prefs.sisReportedConfigured">
                <chronic-absence-card/>
            </v-col>
            <v-col cols="4" v-else>
                <enrollment-card/>
            </v-col>

            <v-col cols=4 v-if="showChrnAbsenceCard && prefs.sisReportedConfigured">
                <enrollment-card/>
            </v-col>
            <v-col cols=4 v-else>
                <quick-links-card/>
            </v-col>

            <v-col cols=4>
                <quick-links-card v-if="showChrnAbsenceCard && prefs.sisReportedConfigured"/>
            </v-col>
        </v-row>
        <fe-crud ref="prefsCrud" :config="prefsCrudConfig" @read="prefsFetched" autoload/>
    </div>
    <div v-else class="d-flex flex-fill flex-wrap" style="overflow: scroll;">
        <v-row class="d-flex" style="min-height: 300px;">
            <v-col cols=4>
                <today-card/>
            </v-col>

            <v-col cols=4>
                <intervention-participation-card :launcpadStyle=false />
            </v-col>

            <v-col cols=4>
                <intervention-tools-card/>
            </v-col>
        </v-row>

        <v-row class="d-flex flex-fill" style="min-height: 300px;">
            <v-col cols=4>
                <visualizations-card/>
            </v-col>

            <v-col cols=4>
                <students-card/>
            </v-col>

            <v-col cols=4>
                <quick-links-card/>
            </v-col>
        </v-row>

    </div>
</template>

<script>
    import LayoutTemplate from '@/components/modules/aatemplate/LayoutTemplate'
    import ProgramEvaluation from '@/components/modules/programevaluation/ProgramEvaluation'
    import Student3d from '@/components/modules/students3d/View'
    import CollectionButton from './CollectionButton'
    import TodayCard from './TodayCard'
    import StudentsCard from './StudentsCard'
    import InterventionParticipationCard from './InterventionParticipationCard'
    import VisualizationsCard from './VisualizationsCard.vue'
    import AttendanceCard from './AttendanceCard.vue'
    import IncidentsCard from './IncidentsCard.vue'
    import ChronicAbsenceCard from './ChronicAbsenceCard.vue'
    import EnrollmentCard from './EnrollmentCard.vue'
    import QuickLinksCard from './QuickLinksCard'
    import InterventionToolsCard from './InterventionToolsCard'
    import { mapState } from 'vuex'

    export default {
        name: 'LaunchPad',
        components: {
            LayoutTemplate,
            ProgramEvaluation,
            CollectionButton,
            Student3d,
            TodayCard,
            StudentsCard,
            InterventionParticipationCard,
            VisualizationsCard,
            AttendanceCard,
            IncidentsCard,
            ChronicAbsenceCard,
            EnrollmentCard,
            QuickLinksCard,
            InterventionToolsCard
        },
        computed: {
            ...mapState('global', ['sessionUser', 'lastLaunchpadVisit', 'dockableWindows', 'districtPreferences']),
            dashboardOnly() {
                return this.sessionUser.district.dashboard_only ? true : false
            },
            useNewLaunchPad() {
                return this.$store.getters['flags/flags']['e-c-split-viz']
            },
            showChrnAbsenceCard() {
                return this.$store.getters['flags/flags']['ec-attendance-mlp-1']
            },
            prefsCrudConfig() {
                let cfg = _.cloneDeep(this.$models.preferences)
                cfg.read.params.property = 'district'
                return cfg
            },
        },
        mounted() {
            this.setupPanels()
        },
        data() {
            return {
                showDialog: true,
                fieldConfig: [{
                    field: 'name',
                    label: 'Incident Type',
                    idProperty: 'id',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 50 },
                    width: '100%',
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        style: {
                            width: '100%'
                        }
                    }
                }, {
                    field: 'created',
                    label: 'Created',
                    idProperty: 'id',
                    component: 'v-text-field',
                    validatorKey: 'text',
                    validatorConfig: { limit: 20 },
                    attrs: {
                        flat: true,
                        solo: true,
                        dense: true,
                        readonly: true,
                        style: {
                            width: '100%'
                        }
                    }
                }],
                formRecords: [],
                tools: [],
                incidents: [],
                deeperOptions: [],
                collections: [],
                cards: [{
                    id: 1,
                    title: 'Action Items'
                }, {
                    id: 2,
                    title: 'My Students'
                }, {
                    id: 3,
                    title: 'Timeline'
                }, {
                    id: 4,
                    title: 'My Collections'
                }],
                prefs: {},
                caReloadKey: 0,
            }
        },
        watch: {
            dockableWindows(v) {
                // If all dockable windows have closed, anticipate that
                // user has just created a new incident (for example) and
                // trigger a refresh of launchpad data
                if (v.length === 0) {
                    this.$store.commit('global/reloadLaunchpad', { })
                }
            },
        },
        methods: {
            go() {
                this.programs.push('program-evaluation')
            },
            setupPanels() {
                let me = this

                this.deeperOptions = [{
                    id: 'layout-templates',
                    title: 'Layout Templates',
                    handler() {
                        me.$router.replace('/LayoutTemplate')
                    }
                }, {
                    id: 'program-evaluation',
                    title: 'Program Evaluation',
                    handler() {
                        me.$router.replace('/ProgramEvaluation')
                    }
                }, {
                    id: 'students-3d',
                    title: 'Students 3D',
                    handler() {
                        me.$router.replace('/Students3D')
                    }
                }, {
                    id: 'analytics',
                    title: 'Analytics',
                    handler() {
                    }
                }]

                this.loadUserData()
            },
            loadUserData() {
                this.$store.dispatch('doQuery', {
                    endpoint: 'dashboard.php?action=get&property=home&_dc=1568385261019&schedule_date_occurrence=2019-09-13',
                    rootProperty: 'dashboard'
                })
                .then(response => {
                    this.collections = response.user_dashboards
                })
            },
            prefsFetched(v) {
                let prefs = {}
                v.forEach(pref => {
                    prefs[pref.code] = pref.user_value !== "0"
                })
                this.prefs.sisReportedConfigured = prefs['ATTENDANCE_EQUIV_RATE']
                this.caReloadKey ++
            }
        }
    }
</script>

<style lang="scss" scoped>
.collection-button {
    height: 72px;
    width: 72px;
    background-color: blue;
}
</style>
