export default {
    chart: {
        type: 'bar',
    },
    credits: {
        enabled: false
    },
    navigation: {
        buttonOptions: {
            enabled: false
        }
    },
    title: {
        enabled: false,
        text: ''
    },
    exporting: {
        filename: '',
        csv: {
            columnHeaderFormatter: function(item, key) {
                if (item.isXAxis) {
                    return item.options.title
                } else {
                    return item.name
                }
            }
        }
    },
    xAxis: {
        categories: ['Test'],
        title: {
            text: undefined
        }
    },
    yAxis: {
        title: {
            text: ''
        },
        labels: {
            formatter: '',
        }
    },
    legend: {
        enabled: false,
        align: 'center'
    },
    plotOptions: {
        line: {
            marker: {
                enabled: false
            }
        },
        bar: {
            dataLabels: {
                enabled: true
            }
        },
        column: {
            dataLabels: {
                enabled: '',
                style: {},
                formatter: ''
            }
        }
    },
    series: []
}
