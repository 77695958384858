<template>
    <fe-card
        class="alt-card ma-3"
        headerText="Incidents"
    >
        <template v-slot:title-text>
            <span class="mr-5 d-inline-block fe-card-title-text" style="font-size: 16px">
                Incidents
            </span>
            <fe-info-tip
                v-if="hasRestrictedIncidentTypes"
                tooltip="There are some incident types that have been restricted from your visibility by your district"
                color="#7e8494! important"
                icon="fal fa-info-circle"
                style="float: right; margin-top: 5px"
            />
            <!-- commenting until product gets copy for tooltip text -->
            <!-- <fe-info-tip
                color="#7e8494! important"
                icon="fal fa-info-circle"
                style="float: right; margin-top: 5px"
                tooltip=""
            >
            </fe-info-tip> -->
        </template>

        <template v-slot:title-toolbar>
            <v-menu class="flex-shrink-1" offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn text icon data-test="incident-menu-btn">
                        <v-icon v-on="on">more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn style="padding: 0" text @click="printChart" data-test="incident-print-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br/>
                        <v-btn style="padding: 0" text @click="exportChart" data-test="incident-download-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </template>

        <template v-slot:body>
            <div style="height: 400px;" id="incident-data-viz">
                <chart
                    v-if="incidentChartData"
                    ref="launchpad-incident-chart"
                    :config="incidentChartData"
                    :menu="false"
                    @chartClicked="incidentClicked"
                    data-test="launchpad-incidents-chart"
                />

                <div
                    v-else
                    style="cursor: pointer; vertical-align: middle; height: 280px;"
                    @click="$router.replace('/Incidents')"
                    data-test="launchpad-incidents-search"
                >
                    <fe-empty-state icon="fa-search" text="No data found.  Click to search"/>
                </div>

                <div class="text-center">{{ startDate }} to {{ endDate }}</div>

                <fe-btn
                    v-if="incidentChartData"
                    @click="exploreIncidentClicked"
                    usage="secondary"
                    style="float: right; margin-top: 30px;"
                    data-test="incident-explore-btn"
                >
                    Explore
                </fe-btn>
            </div>

        </template>
    </fe-card>
</template>

<script>
import {mapState} from 'vuex'
import Chart from "@/components/charts/Chart"
import windowOptionsMixin from '@/mixins/windowOptions'

export default {
    name: 'IncidentsCard',

    components: {
        Chart,
    },

    mixins: [windowOptionsMixin],

    data() {
        return {
            tools: [],
            items: [],
            incidentChartData: false,
            endDate: undefined,
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool', 'lastLaunchpadVisit']),

        startDate() {
            if (!this.endDate) return undefined
            return this.$dayjs(this.endDate).subtract(14, 'd').format('YYYY-MM-DD')
        },

        hasRestrictedIncidentTypes() {
            return (!!this.sessionUser.user?.has_restricted_incident_types)
        },

        incidentChart() {
            return this.$refs['launchpad-incident-chart']
        }
    },

    watch: {
        endDate(n, o) {
            if (o && n && n !== o) {
                this.loadChartData()
            }
        }
    },

    mounted() {
        this.endDate = this.$dayjs().format('YYYY-MM-DD')
        this.loadIncidents()

    },

    methods: {
        loadIncidents() {
            let url = this.$models.getUrl('studentIncidentChart', 'read');
            let p = {
                chart_types: ['incident_behavior'],
                start_date: this.startDate,
                end_date: this.endDate,
                school_id: this.defaultSchool?.id,
            }

            return this.$axios.get(url + '&' + this.$objectToParams(p))
                .then(response => {
                    let data = this.$ecResponse(response, 'incidents')

                    data.charts.forEach((chart) => {
                        let categories = chart.fields ? chart.fields : []

                        if (categories.length > 0) {
                            let series = this.buildSeries(categories, chart)

                            this.incidentChartData = {
                                chart: {
                                    type: 'bar',
                                    height: '300px'
                                },
                                title: false,
                                subtitle: false,
                                xAxis: {
                                    categories: categories,
                                    labels: {
                                        enabled: false
                                    },
                                    title: {
                                        text: null
                                    }
                                },
                                yAxis: {
                                    endOnTick: false,
                                    title: {
                                        text: 'Total Count',
                                        align: 'high'
                                    },
                                    labels: {
                                        overflow: 'justify'
                                    }
                                },
                                plotOptions: {
                                    bar: {
                                        dataLabels: {
                                            enabled: true
                                        }
                                    }
                                },
                                legend: false,
                                credits: {
                                    enabled: false
                                },
                                series: series
                            }
                        }
                    })
                })
        },

        exploreIncidentClicked() {
            let chartParams = {
                start_date: this.startDate,
                end_date: this.endDate,
                school_year_id: [{id: this.currentYear.id, name: this.currentYear.name}],
            }
            if (this.defaultSchool?.id) chartParams.school_id = this.defaultSchool.id

            this.openIncidentWindow(chartParams, [])
        },

        incidentClicked(event, point) {
            let incidentId = point.incident_behavior_id
            let incidentName = point.incident_behavior_name.match(/.+-\s(.+)/)[1]

            let chartParams = {
                start_date: this.startDate,
                end_date: this.endDate,
                school_year_id: [{id: point.school_year_id, name: point.school_year_name}],
                incident_behavior_id: [{id: incidentId, name: incidentName}],
            }
            if (this.defaultSchool?.id) chartParams.school_id = this.defaultSchool.id

            let chartFilters = [{
                key: 'incident_behavior_id',
                name: incidentName,
                id: incidentId
            }]

            this.openIncidentWindow(chartParams, chartFilters)
        },

        openIncidentWindow(chartParams, chartFilters) {
            this.$store.commit('global/addDockableWindow', {
                name: 'Incident Charting',
                component: 'incident-charting',
                attrs: {
                    chartParams,
                    chartFilters,
                    windowed: true,
                }
            })
        },

        buildSeries(categories, cfg) {
            let series = []

            cfg.data.forEach(rec => {
                let exists = series.find(serie => {
                    return serie.name === rec.school_year_name
                })

                if (!exists) {
                    series.push({
                        name: rec.school_year_name,
                        schoolYearId: rec.school_year_id,
                        data: []
                    })
                }
            })

            series.forEach(serie => {
                categories.forEach(cat => {
                    let rec = cfg.data.find(data => data[cfg.x_field] === cat)
                    let data = rec ? rec : {}
                    data.y = rec ? rec.total_count : 0
                    serie.data.push(data)
                })
            })

            return series
        },

        printChart() {
            this.incidentChart.printChart()
        },

        exportChart() {
            this.incidentChart.exportChart({
                type: 'image/png',
                filename: 'Incident Summary'
            }, true)
        }
    }
}
</script>

<style lang="scss" scoped>
</style>
