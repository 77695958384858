<template>
    <chart-panel class="chart-container" border>
        <div class="d-flex title-area" style="border-bottom: 1px solid rgb(224, 225, 230);">
            <div class="d-flex">
                <div class="my-3 ml-3" style="font-size: 18px; padding-right: 10px;">Chronic Absence Rate</div>
                <fe-info-tip
                    color="#495B8F !important"
                    icon="fal fa-info-circle"
                    size="14px"
                    style="padding-right: 8px; align-self: center;"
                    tooltip="Chronic absence is when a student is absent (excused or unexcused) for at least 10% of the days. The overall rate indicates the percentage of students who are chronically absent in a given time period."
                    />
            </div>

            <v-spacer></v-spacer>

            <v-menu offset-y left >
                <template v-slot:activator="{ on }">
                    <v-btn text icon :data-test="`ca-rate-chart-extra-menu-items`">
                        <v-icon v-on="on" @click="close = false">more_vert</v-icon>
                    </v-btn>
                </template>
                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items" ></slot>
                        <v-btn class="pa-0" text @click="printChart" :data-test="`ca-rate-chart-print-btn`">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br>
                        <v-btn class="pa-0" text @click="exportChart" :data-test="`ca-rate-chart-dwnl-img-btn`">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                        <br>
                        <v-btn class="pa-0" text @click="exportCSV" :data-test="`ca-rate-chart-dwnl-csv-btn`">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download CSV
                        </v-btn>
                        <br>
                        <v-btn class="pa-0" text @click="toggleCounts()" data-test="ca-rate-chart-show-hide-count-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-hashtag</v-icon>
                            {{ toggleItemName }}
                        </v-btn>
                        <br>
                    </v-card>
                </slot>
            </v-menu>
        </div>

        <div class="d-flex field-area">
            <v-spacer/>
            <fe-remote-combo
                v-model="selectedTimePeriod"
                class="mr-3"
                style="width: 160px"
                :items="timePeriodOptions"
                :clearable="false"
                @input="timePeriodInput"
                data-test="ca-rate-chart-year-picker"
            />
        </div>

        <div class="chart-area">
            <fe-spinner v-if="!ready" class="spinner"/>

            <data-unavailable v-else-if="error" data-type="chronic absence"/>

            <no-filter-results
                v-else-if="noFilterResults"
                data-type="chronic absence"
                @clearFilters="$emit('clearAllFilters')"
            />

            <highcharts
                v-else
                id="caRatetId"
                ref="chronicAbsenceRateChart"
                class="ca-rate-chart"
                :options="chartConfig"
            />
        </div>
    </chart-panel>
</template>

<script>
import ChartPanel from '@/components/charts/ChartPanel'
import * as ChartExportUtil from '../common/util/ChartExports'
import DataUnavailable from './emptyStateMessaging/DataUnavailable.vue'
import NoFilterResults from './emptyStateMessaging/NoFilterResults.vue'

export default {
    name: 'ChronicAbsenceRateChart',

    components: {
        ChartPanel,
        DataUnavailable,
        NoFilterResults
    },

    props: {
        data: {
            type: Object,
            required: true
        },
        year: {},
        ready: {
            type: Boolean,
            default: true
        },
        error: {
            type: Boolean,
            default: false
        },
        noFilterResults: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            selectedTimePeriod: {},
            timePeriodOptions: [{
                name: 'This Year (Monthly)',
                id: '0'
            }, {
                name: 'Last 2 Years (Monthly)',
                id: '-2'
            }, {
                name: 'Last 5 Years (Monthly)',
                id: '-5'
            }, {
                name: 'Last 2 Years (Annual)',
                id: '-2'
            },
            {
                name: 'Last 5 Years (Annual)',
                id: '-5'
            }],
            toggleEnabled: true
        }
    },

    computed: {
        chartConfig() {
            return this.data.config
        },

        toggleItemName() {
            return (this.toggleEnabled ? 'Hide' : 'Show') + ' Counts on Chart'
        }
    },

    mounted() {
        this.selectedTimePeriod = this.year
    },

    methods: {
        timePeriodInput(period) {
            if (period && period !== this.year) {
                this.$emit('change', 'timePeriod', period)
            }
        },

        printChart() {
            ChartExportUtil.printChartById('caRatetId')
        },

        exportChart() {
            ChartExportUtil.exportChart(this.$refs['chronicAbsenceRateChart'].chart, 'Chronic Absence Rate')
        },

        exportCSV() {
            this.$refs['chronicAbsenceRateChart'].chart.downloadCSV()
        },

        toggleCounts() {
            let labelEnabled = this.data.config.plotOptions.column.dataLabels.enabled
            this.$emit('change', 'countToggle', !labelEnabled)

            setTimeout(() => {
                this.toggleEnabled = !labelEnabled
            }, 500)
        }
    }
}
</script>

<style scoped lang="scss">
.chart-container {
    background: #fff;
    min-height: 523px;

    .field-area {
        height: 56px;
        padding-top: 10px;
    }

    .chart-area {

        .ca-rate-chart {
            height: 100%;

            ::v-deep * {
                font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
            }

            ::v-deep .highcharts-container {
                width: 100% !important;
            }

            &-menu {
                &-btn {
                    position: relative;
                    top: -400px;
                    z-index: 1;
                    float: right;
                    color: #7E8494 !important;
                }
            }
        }
    }
}

.spinner {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 250px !important;
}
</style>
