<template>
    <div>
        <fe-card
            class="alt-card ma-3"
            headerText="Implementation Fidelity"
        >
            <template v-slot:body>
                <div :style='launchpadStyle ? "height: 215px;" : null'>
                    <fe-dialog
                        title="Active Plan Details"
                        v-if="dialog.show"
                        @accept="dialog.show=false"
                        @close="dialog.show=false"
                        persistent
                        dismissButtonText="Cancel"
                        acceptButtonText="Done"
                        :width="gridWidth"
                    >
                        <fe-grid
                            style="height: 400px;"
                            :rowData="dialog.data"
                            :columnDefs="columns"
                            displayDensity="small"
                            groupUseEntireRow
                        />
                    </fe-dialog>

                    <v-skeleton-loader
                        class="mx-auto"
                        type="text@5"
                        :loading="isLoading"
                        style="min-height: 120px;"
                    >
                        <div class="d-flex justify-center align-center" style="height: 150px;" v-if="showCharts">
                            <v-layout row>
                                <v-flex xs6>
                                    <highcharts ref="chart-a" :options="fidelityConfig" class="implementation-fidelity-chart"/>
                                </v-flex>
                                <v-flex xs6>
                                    <highcharts ref="chart-b" :options="proceduresConfig" class="implementation-fidelity-chart"/>
                                </v-flex>
                            </v-layout>
                        </div>

                        <div v-else class="text-center">
                            No data found
                        </div>
                    </v-skeleton-loader>
                </div>
            </template>
        </fe-card>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import Highcharts from 'highcharts'
    import highchartsMore from 'highcharts/highcharts-more'
    import solidGaugeInit from 'highcharts/modules/solid-gauge'
    import windowOptionsMixin from '@/mixins/windowOptions'
    highchartsMore(Highcharts)
    solidGaugeInit(Highcharts)
    export default {
        name: 'ImplementationFidelity',
        mixins: [ windowOptionsMixin ],
        props: {
            params: {
            },
            launchpadStyle: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            params: {
                handler(v) {
                    if (v && Object.values(v).length) this.loadData()
                },
                immediate: true
            },
            lastLaunchpadVisit(v) {
                if (!this.reloading) {
                    this.reloading = true
                    this.loadData()
                }
            },
        },
        computed: {
            ...mapState('global', ['lastLaunchpadVisit']),
            gridWidth() {
                return window.innerWidth-50
            }
        },
        data() {
            return {
                items: [],
                isLoading: false,
                reloading: false,
                showCharts: false,
                participationConfig: {},
                procedureConfig: {},
                columns: [{
                   headerName: "Student",
                   field: "student_full_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "Strategy",
                   field: "intervention_type_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "Interventionist",
                   field: "user_full_name",
                   editable: false,
                   width: 150
                }, {
                   headerName: "School",
                   field: "school_name",
                   editable: false,
                   flex: 1
                }, {
                   headerName: "Grade",
                   field: "grade_desc",
                   editable: false,
                   width: 80
                }, {
                   headerName: "Start Date",
                   field: "intervention_plan_start_date",
                   editable: false,
                   width: 100
                }, {
                   headerName: "End Date",
                   field: "intervention_plan_end_date",
                   editable: false,
                   width: 100
                }],
                dialog: {
                    show:false,
                    data: []
                },
                proceduresConfig: {},
                fidelityConfig: {}
            }
        },
        methods: {
            async loadData() {
                this.isLoading = true
                this.showCharts = false
                await this.$axios.get('interventionView.php?action=get&property=overall_fidelity&' + this.$objectToParams(this.params))
                    .then(r => {
                        this.reloading = false
                        this.items = this.$ecResponse(r, 'overall_fidelity')
                        this.processData()

                    })
                    .finally(() => {
                        this.isLoading = false
                        this.showCharts = true
                    })
            },
            processData() {
                let pn = this.$twoDecimals(this.items.attendance_pct)
                let prn = this.$twoDecimals(this.items.procedure_pct)

                this.proceduresConfig = this.gaugeConfig('PARTICIPATION<br>RATE', pn)
                this.fidelityConfig = this.gaugeConfig('PROCEDURES<br>COMPLETED', prn)
            },
            gaugeConfig(title, count) {
                return {
                    chart: {
                        type: 'pie',
                        height: '120px',
                        width: 120,
                        plotShadow: false,
                        margin: [7, 5, 7, 5]
                    },
                    credits: {
                        enabled: false
                    },
                    title: {
                        verticalAlign: 'middle',
                        floating: true,
                        text: '<span style="font-size: 16px;">' + count + '%</span><br>' + title,
                        style: {
                            marginTop: '20px',
                            fontSize: 12
                        }
                    },
                    exporting: {
                        enabled: false
                    },

                    tooltip: {
                        enabled: false,
                        borderWidth: 0,
                        backgroundColor: '#FFFFFF',
                        style: {
                            fontSize: '16px'
                        },
                        valueSuffix: '%',
                    },

                    plotOptions: {
                        pie: {
                            innerSize: '100%',
                            borderWidth: 12,
                            borderColor: null,
                            slicedOffset: 0,
                            dataLabels: {
                                connectorWidth: 0,
                                enabled: false
                            }
                        }
                    },

                    series: [{
                        name: 'On Track',
                        data: [{
                            name: 'Not On Track',
                            color: '#e6e6e6',
                            sliced: true,
                            y: 100 - count
                        }, {
                            name: 'On Track',
                            color: '#408AAF',
                            sliced: true,
                            y: count
                        }]
                    }]
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
.clickable {
    cursor: pointer;
}
.implementation-fidelity-chart {
    justify-content: center;
    align-items: center;
    display: flex;
    ::v-deep * {
        font-family: 'CerebriSans-Regular', Cerebri Sans, 'Roboto', sans-serif !important;
    }
}
</style>
