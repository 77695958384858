<template>
    <div class="guardian-reporting">
        <a class="mr-5 mt-5" @click="goToSmartForms">
            <i style="color: #006c90;" class="fal fa-chevron-left"></i> Back to smartFORMS</a>
        <h1 class="mt-4">Guardian Emails for {{ templateName }} Results</h1>
        <fe-filter-btn
            data-test="last-sent-filter-btn"
            class="mt-3"
            style="margin-left: -8px !important;"
            title="Last Sent Date"
            :items="processedDateOptions"
            itemText="label"
            itemValue="id"
            :value="filters.dates"
            @input="onFilterDate"
            :multiple="false"
            :closeOnSelect="false"
        />

        <fe-filter-btn
            data-test="sent-by-filter-btn"
            title="Sent By"
            :items="sentByUsers"
            itemText="label"
            itemValue="userId"
            :value="filters.sentBy"
            @input="onFilterSentBy"
            :multiple="false"
            :closeOnSelect="false"
        />

        <fe-dialog
            v-if="showSettings"
            data-test="settings-modal"
            :dismissable="false"
            :header="false"
            :footer="false"
            persistent
            disableAutoclose
        >
            <div class="left-align-content mt-6">
                <h2 class="mb-6 mt-4">Resend {{ selections.length > 1 ? 'smartFORMS' : '1 smartFORM' }}</h2>

                <p>You have selected {{ selections.length > 1 ? `${selections.length} smartFORMS` : '1 smartFORM' }} to resend. Choose to translate forms and have guardians acknowledge reviewing forms.</p>

                <v-checkbox
                    v-if="guardianReportingV3Display"
                    ref="translateFormResendCheckbox"
                    v-model="translateForm"
                >
                    <template v-slot:label>
                        <span style="font-size: 14px;">
                            Translate the linked {{ selections.length > 1 ? 'smartFORMS' : 'smartFORM' }} into the student’s home language, if other than English.
                        </span>
                    </template>
                </v-checkbox>

                <v-checkbox
                    v-if="guardianReportingV3Display"
                    ref="requireResponseResendCheckbox"
                    v-model="responseRequired"
                >
                    <template v-slot:label>
                        <span style="font-size: 14px;">
                            Prompt guardians to acknowledge they have reviewed each form.
                        </span>
                    </template>
                </v-checkbox>
            </div>

            <div class="right-align-btns mt-2">
                <fe-btn data-test="resend-confirmation-cancel-btn" usage="secondary" @click="clearSelections">
                    Cancel
                </fe-btn>
                <fe-btn 
                    data-test="resend-confirmation-got-it-btn" 
                    usage="primary" 
                    @click="resendGuardianEmails(singleFormData)"
                >
                    Resend {{ selections.length > 1 ? 'Forms' : 'Form' }}
                </fe-btn>
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="showResendConfirmation"
            data-test="sending-smartform-confirmation-modal"
            :dismissable="false"
            :header="false"
            :footer="false"
            persistent
            disableAutoclose
        >
            <div class="text-center mt-6">
                <v-img
                    src="@/assets/paper-airplane.png"
                    max-width="120"
                    style="margin:0 auto;"
                />

                <h2 class="mb-6 mt-4">Resending {{ selections.length > 1 ? 'smartFORMS' : 'smartFORM' }}</h2>
            </div>

            <p>Your {{ selections.length > 1 ? 'smartFORMS are on their' : 'smartFORM is on its' }} way! It may take up to a few hours to send all the emails. You will be
                notified in eduCLIMBER once all the emails have been sent.</p>

            <p>Check back later to see if any emails could not be delivered. You may need to resend emails or update
                records if emails provided are not valid.</p>

            <div class="text-center mt-2">
                <fe-btn data-test="send-confirmation-got-it-btn" usage="primary" @click="handleResendConfirmation">
                    Got It
                </fe-btn>
            </div>
        </fe-dialog>

        <fe-dialog
            v-if="showFailureModal"
            data-test="sending-smartform-failure-modal"
            :dismissable="false"
            :header="false"
            :footer="false"
            persistent
            disableAutoclose
        >
            <h2 class="mb-4 mt-6">
                <v-icon class="mr-2" style="color:#BF0D00">far fa-exclamation-circle</v-icon>
                Temporary Error
            </h2>
            <p>eduCLIMBER is experiencing a technical difficulty and your emails were not sent. Please try again. If the
                error persists, contact customer support.</p>

            <div class="mt-2" style="margin-left:-8px;">
                <fe-btn usage="primary" @click="handleResendFailure">Got It</fe-btn>
            </div>
        </fe-dialog>

        <div class="count-groups-container">
            <span class="count-group mt-5">
                <img src="/images/guardians-emailed.png"/>
                <p class="count-text">
                    <b class="count">{{ totalSent }}</b>
                    <br>
                    Guardians emailed
                </p class="count-text">

                <img class="ml-10" src="/images/guardians-delivered.png"/>
                <p class="count-text">
                    <b class="count">
                        {{ delivered }}
                    </b>
                    <br>
                    <span class="count-info">
                        Delivered
                        <fe-tooltip
                            tooltip="The email with the linked smartFORM(s) has been delivered to a guardian’s email inbox. This does not indicate whether the email  has been read or seen yet."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">

                <img class="ml-10" src="/images/guardians-undelivered.png"/>
                <p class="count-text">
                    <b class="count">{{ undeliverable }}</b>
                    <br>
                    <span class="count-info">
                        Not Received
                        <fe-tooltip
                            tooltip="The email was not successfully delivered. You may be able to resend the smartFORM(s) to these guardians. You may need to take care of any blocked or invalid email addresses."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">
            </span>

            <span class="count-group mt-5" v-if="responseRequired">
                <img src="/images/guardians-responded.png"/>
                <p class="count-text">
                    <b class="count">{{ responseReceived }}</b>
                    <br>
                    <span class="count-info">
                        Responded
                        <fe-tooltip
                            tooltip="These guardians have  acknowledged reviewing  the smartFORM(s) they received."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">


                <img class="ml-10" src="/images/guardians-no-response.png"/>
                <p class="count-text">
                    <b class="count">{{ noResponse }}</b>
                    <br>
                    <span class="count-info">
                        No Response
                        <fe-tooltip
                            tooltip="These guardians have received the email with the linked smartFORM(s) but have not acknowledged reviewing them."
                        >
                            <i class="fas fa-info-circle"></i>
                        </fe-tooltip>
                    </span>
                </p class="count-text">
            </span>
        </div>

        <v-tabs height="48px" v-model="activeTab" class="ec-collection-tabs mt-4 mb-4">
            <v-tab class="small-tab small-tab-all" data-test="guardians-tab">Guardians</v-tab>
            <v-tab class="small-tab small-tab-all" data-test="undelivered-tab">Not Received</v-tab>
            <v-tab class="small-tab small-tab-all" data-test="responded-tab">Responded</v-tab>
            <v-tab class="small-tab small-tab-all" data-test="no-response-tab">No Response</v-tab>
        </v-tabs>

        <div v-if="activeTab === 1">
            <p class="mt-6">The following guardian emails were not successfully delivered. You can try to resend emails that bounced due to a temporary error. Emails that are blocked cannot be resent. Update guardian contact information in your Student Information System (SIS) or through a Student Guardian file upload.</p>
            <div v-if="guardianReportingV3Display" >
                <v-checkbox
                    v-model="showOnlyResendable"
                    @change="fetchData"
                    class="mt-6"
                    style="margin-left: -10px;"
                    ref="showOnlyResendableCheckbox"
                    >
                    <template v-slot:label>
                        <span class="checkbox-label">Show only emails that can be resent </span>
                    </template>
                </v-checkbox>
            </div>
        </div>

        <p v-else-if="activeTab === 2" class="mt-6">The following guardians have acknowledged they have reviewed each form. This means they were able to access the shared form and indicated that they reviewed the form.</p>

        <div v-else-if="activeTab === 3">
            <p class="mt-6">The following guardians received the email but have not acknowledged they have reviewed each form. If the link is no longer valid, you will need to resend the form.</p>
            <div v-if="guardianReportingV3Display" data-test="resendable-filter-checkbox">
                <v-checkbox v-model="excludeStudentsWithResponse" @change="fetchData" class="mt-6" style="margin-left: -10px;">
                    <template v-slot:label>
                        <span class="checkbox-label">Exclude students with at least one guardian response</span>
                    </template>
                </v-checkbox>
            </div>
        </div>


        <div class="no-sent-data" v-if="apiFailed">
            <img src="/images/guardian-sent-api-failed.png"/>
            <h5>No data available</h5>
            <p>We are experiencing technical difficulties. Refresh the page or try again later.</p>
            <fe-btn
                data-test="refresh-btn"
                usage="primary"
                @click="refresh"
            >
                Refresh
            </fe-btn>
        </div>
        <div class="no-sent-data" v-else-if="activeTab === 1 && showOnlyResendable && !rowData.length">
            <img src="/images/no-guardians.png"/>
            <h5>No bounced guardian emails</h5>
            <p>There are no bounced guardian emails that need to be resent.</p>
        </div>
        <div class="no-sent-data" v-else-if="!rowData.length">
            <img src="/images/no-guardians.png"/>
            <h5>{{ noSentDataContent.h5 }}</h5>
            <p>{{ noSentDataContent.p }}</p>
        </div>

        <fe-grid
            ref="grid"
            class="guardian-reporting-grid"
            v-else
            :showToolbar="true"
            style="height: 500px;"
            :columnDefs="columnDefs"
            :rowData="rowData"
            :frameworkComponents="frameworkComponents"
            :showAddRowBtn="false"
            @selectionChanged="doSelection"
            @gridReady="onGridReady"
        >
            <template #toolbar-items v-if="selections.length > 0">
                <v-slide-y-transition>
                    <div class="d-flex selection-toolbar background-default ml-5">
                        <div class="selected-count mt-3 ml-5">{{ selections.length }} Selected</div>
                        <div class="ml-auto">
                            <v-btn
                                text
                                class="white--text"
                                data-test="bulk-download-btn"
                                @click="bulkDownload"
                            >
                                <i class="fal fa-arrow-alt-to-bottom mr-2 mt-1"></i>
                                Download ZIP File
                            </v-btn>
                            <v-btn
                                text
                                class="white--text"
                                @click="showSettings = true"
                                data-test="resend-forms-btn"
                            >
                                <i class="fal fa-share mr-2"></i>
                                Resend Forms to Guardians
                            </v-btn>
                            <v-btn
                                text
                                class="white--text"
                                @click="clearSelections"
                                data-test="selections-toolbar-cancel-btn"
                            >
                                <i class="fal fa-times mr-2"></i>
                                Cancel
                            </v-btn>
                        </div>
                    </div>
                </v-slide-y-transition>
            </template>
            <template #cellDialog="{cell}">
                <v-list>
                    <v-list-item @click="showSettings = true; singleFormData = cell.data" data-test="resend-single-btn">
                        Resend guardian email
                    </v-list-item>
                    <v-list-item @click="singleDownload(cell.data)" data-test="download-single-btn">
                        Download/print form
                    </v-list-item>
                </v-list>
            </template>
        </fe-grid>
    </div>
</template>

<script>
import checkboxHeader from '../checkboxHeader'
import SmartFormMixin from '../smartFormMixin'

export default {
    name: 'GuardianEmailReporting',
    mixins: [SmartFormMixin],
    data() {
        return {
            activeTab: 0,
            templateId: '',
            templateName: '',
            totalSent: 0,
            delivered: 0,
            undeliverable: 0,
            responseReceived: 0,
            responseRequired: 0,
            noResponse: 0,
            rowData: [],
            apiFailed: false,
            excludeStudentsWithResponse: false,
            selections: [],
            emailing: false,
            showResendConfirmation: false,
            showFailureModal: false,
            showSettings: false,
            showOnlyResendable: false,
            translateForm: 0,
            selectAllCheckbox: false,
            filters: {
                dates: {
                    included: [],
                },
                sentBy: {
                    included: [],
                },
            },
            dateOptions: [
                { label: 'Last 7 Days', value: { startDate: this.$dayjs().subtract(7, 'day').format('YYYY-MM-DD'), endDate: this.$dayjs().format('YYYY-MM-DD') } },
                { label: 'Last 30 Days', value: { startDate: this.$dayjs().subtract(30, 'day').format('YYYY-MM-DD'), endDate: this.$dayjs().format('YYYY-MM-DD') } },
                { label: 'Last 90 Days', value: { startDate: this.$dayjs().subtract(90, 'day').format('YYYY-MM-DD'), endDate: this.$dayjs().format('YYYY-MM-DD') } },
            ],
            sentByUsers: [],
            singleFormData: null,
        }
    },
    computed: {
        columnDefs() {
            let columns = [];
            
            // Add checkbox column to at the begining for relevant tabs
            if (this.guardianReportingV3Display && (this.activeTab === 1 || this.activeTab === 3)) {
                columns.push({
                    headerComponent: 'checkboxHeader',
                    headerComponentParams: {
                        selectAll: this.selectAllRows.bind(this),
                        selectAllCheckbox: false,
                    },
                    checkboxSelection: true,
                    width: 70,
                    minWidth: 70,
                    colId: 'checkbox_column',
                })
            }

            // Add standard columns for all tabs
            columns.push(
                { headerName: 'Student', field: 'studentName', flex: 1, sortable: true },
                { headerName: 'Guardian Email', field: 'guardianEmail', flex: 1, sortable: true },
                { headerName: 'Form Name', field: 'formName', flex: 1, sortable: true },
                { headerName: 'School', field: 'schoolName', flex: 2, sortable: true },
                { headerName: 'Sent By', field: 'sentBy', flex: 1, sortable: true, hide: true },
                { headerName: 'Email Sent',
                    field: 'sentDate',
                    flex: 1,
                    sortable: true,
                    valueFormatter: v => v.data.sentDate ? this.$dayjs(v.data.sentDate).format('M/D/YY') : 'Sending'
                }
            )

            // Add tab-specific columns at the end
            switch (this.activeTab) {
                case 1:
                    columns.push({
                        headerName: 'Error Details',
                        field: 'errorDetails',
                        flex: 1,
                        sortable: true,
                        cellRenderer: this.errorDetailsCellRenderer,
                    })
                    break
                case 2:
                    columns.push(
                        { headerName: 'Responded', field: 'respondedDate', flex: 1, sortable: true, valueFormatter: v => v.data.respondedDate ? this.$dayjs(v.data.respondedDate).format('M/D/YY') : 'No response' },
                    )
                    break
                default:
                    columns.push(
                        { headerName: 'Responded', field: 'respondedDate', flex: 1, sortable: true, valueFormatter: v => v.data.respondedDate ? this.$dayjs(v.data.respondedDate).format('M/D/YY') : 'No response' },
                        { headerName: 'Link Valid Until', field: 'expirationDate', flex: 1, sortable: true, valueFormatter: v => this.$dayjs(v.data.expirationDate).format('M/D/YY') }
                    )
                    break
            }

            if (this.activeTab !== 2) {
                columns.push({
                    maxWidth: 70,
                    cellRenderer: v => '<i class="fe-grid-icon far fa-ellipsis-h theme--light"></i>',
                    onCellClicked: v => this.$refs.grid.setDialogCell(v),
                    cellStyle: {
                        display: 'flex'
                    },
                    colId: 'actions'
                })
            }

            return columns
        },
        frameworkComponents() {
            return {
                checkboxHeader: checkboxHeader
            }
        },
        guardianReportingV3Display() {
            return this.$store.getters['flags/flags']['e-c-guardian-notifications-v-3-filters-and-resends']
        },
        noSentDataContent() {
            switch (this.activeTab) {
                case 1:
                    return {
                        h5: 'Emails not received',
                        p: 'Any emails that were not received by guardians and might need to be resent will be listed here.'
                    }
                case 2:
                    return {
                        h5: 'Guardians responded',
                        p: 'Guardians who have responded to forms that were sent will be listed here.'
                    }
                case 3:
                    return {
                        h5: 'Guardians who have not responded',
                        p: 'Guardians who have not responded to the forms that were sent will be listed here.'
                    }
                default:
                    return {
                        h5: 'No guardian emails sent',
                        p: 'There are no guardian emails sent during this time period.'
                    }
            }
        },
        processedDateOptions() {
            return this.dateOptions.map((option, index) => ({
                ...option,
                id: index.toString(),
            }))
        },
    },
    methods: {
        fetchData() {
            let apiEndpoint
            switch (this.activeTab) {
                case 1:
                    apiEndpoint = 'guardianFormReportingUndeliverable'
                    break
                case 2:
                    apiEndpoint = 'guardianFormReportingResponded'
                    break
                case 3:
                    apiEndpoint = 'guardianFormReportingNoResponse'
                    break
                default:
                    apiEndpoint = 'guardianFormReporting'
            }

            const params = { templateIds: this.templateId }
            if (this.excludeStudentsWithResponse) {
                params.anyResponse = true
            }
            
            if (this.showOnlyResendable) {
                params.resendable = true
            }

            const dateFilters = this.filters.dates.included.map(x => x.value)
            if (dateFilters.length) {
                params.startDate = dateFilters[0].startDate
                params.endDate = dateFilters[0].endDate
            }

            const sentByFilter = this.filters.sentBy.included[0]
            if (sentByFilter) {
                params.userIds = sentByFilter.userId
            }

            // Clear current data before making new request
            this.rowData = []

            this.$modelGet(apiEndpoint, params)
                .then(response => {
                    const schoolYearDates = response.school_year_dates
                    if (schoolYearDates && !this.dateOptions.some(option => option.label === 'This School Year')) {
                        if (schoolYearDates.this_year_start_date) {
                            this.dateOptions.push({
                                label: 'This School Year',
                                value: {
                                    startDate: schoolYearDates.this_year_start_date,
                                    endDate: schoolYearDates.this_year_end_date ? schoolYearDates.this_year_end_date : null
                                }
                            })
                        }
                        if (schoolYearDates.last_year_start_date && schoolYearDates.last_year_end_date) {
                            this.dateOptions.push({
                                label: 'Last School Year',
                                value: {
                                    startDate: schoolYearDates.last_year_start_date,
                                    endDate: schoolYearDates.last_year_end_date
                                }
                            })
                        }
                    }

                    // Set sentByUsers prior to filtering out other users
                    if (!this.filters.sentBy.included.length) {
                        this.sentByUsers = [...new Set(response.sent_list.map(item => item.sentBy))]
                            .map(sentBy => ({
                                label: sentBy,
                                userId: response.sent_list.find(item => item.sentBy === sentBy).userId
                            }))
                    }

                    if (this.activeTab === 1) {
                        this.rowData = response.sent_list.map(item => ({
                            ...item,
                            errorDetails: this.getErrorDetails(item)
                        }))
                    } else {
                        this.rowData = response.sent_list
                    }
                })
                .catch(err => {
                    console.warn(`Unable to load data from ${apiEndpoint}`, err)
                    this.apiFailed = true
                })
        },
        refresh() {
            this.apiFailed = false
            this.fetchData()
        },
        updateDataFromRoute(route) {
            this.templateId = route.query.templateId
            this.templateName = route.query.templateName
            this.activeTab = Number(route.query.activeTab) || 0

            if (route.query.dateFilter) {
                const dateFilter = JSON.parse(route.query.dateFilter);
                this.filters.dates = {
                    included: [dateFilter]
                }
            }

            this.fetchData()
            this.fetchCounts()
        },
        getErrorDetails(item) {
            //Priority order is important because multiple scenarios can be true for an email
            const conditions = [
                { condition: item.blocked, message: 'Blocked' },
                { condition: item.bounced, message: 'Bounced' },
                { condition: item.sentDate && !item.deliveredDate, message: 'Not Recieved' }
            ]

            for (const { condition, message } of conditions) {
                if (condition) {
                    return message
                }
            }

            return 'Unable to send'
        },
        errorDetailsCellRenderer(params) {
            const errorDetails = params.data.errorDetails
            let tooltipText = ''
            let icon = ''

            switch (errorDetails) {
                case 'Blocked':
                    tooltipText = 'An address is blocked when the recipient rejected the email. This can happen for a variety of reasons, such as an invalid email or the email is flagged as spam. You will need to use a different email address for this guardian. Update your records before resending.'
                    icon = 'ban'
                    break
                case 'Bounced':
                    tooltipText = 'An email bounces when there is a temporary issue. For example, the recipient’s email server may have been down when the email was sent. You can resend emails to try to resolve this issue.'
                    icon = 'exclamation-circle'
                    break
                case 'Not Recieved':
                    tooltipText = "The form has been sent, but the guardian's email server hasn't confirmed receipt of the form yet. Check back later to see if the email has been received or if it needs to be resent."
                    icon = 'exclamation-circle'
                    break
                default:
                    tooltipText = 'This form was not sent due to a temporary system error. You can resend this email to try to resolve this issue.'
                    icon = 'exclamation-circle'
            }

            return `<div style="color: #BF0D00;" title="${tooltipText}"><i class="fal fa-${icon}"></i> ${errorDetails}</div>`
        },
        goToSmartForms() {
            this.$router.push({
                path: '/smartFORMS',
                query: { activeTab: 5 }
            })
        },
        selectAllRows(params) {
            let selectedNodes = []
            this.$refs.grid.gridOptions.api.forEachNode(node => selectedNodes.push(node))
            for (let i = 0; i < selectedNodes.length - 1; i++) {
                params ? selectedNodes[i].setSelected(true, false, true) : selectedNodes[i].setSelected(false, false, true)
            }
            params ? selectedNodes[selectedNodes.length - 1].setSelected(true) : selectedNodes[selectedNodes.length - 1].setSelected(false)
        },
        doSelection() {
            this.selections = this.$refs.grid.gridApi.getSelectedRows()
        },
        async resendGuardianEmails(formData) {
            if (this.emailing) return
            this.emailing = true

            try {
                const formInstances = formData 
                    ? [formData.formInstanceId]
                    : this.selections.map(selection => selection.formInstanceId)
                
                const response = await this.$modelGet('guardianFormEmailSend', {
                    form_instances: formInstances,
                    require_response: this.responseRequired ? '1' : '',
                    translate_form: this.translateForm ? '1' : '',
                })
                
                if (!response?.success) {
                    throw new Error('Failed to send guardian emails')
                }
                this.showResendConfirmation = true
                this.showSettings = false
                this.singleFormData = null 
            } catch {
                this.showFailureModal = true
                this.showResendConfirmation = false
            } finally {
                this.emailing = false
            }
            this.confirmSettings()
        },
        onGridReady(grid) {
            this.gridApi = grid.api
            this.columnApi = grid.columnApi
            
            this.restoreColumnVisibility()
        },
        updateColumnDefs() {
            if (this.gridApi) {
                this.gridApi.setColumnDefs([])
                this.gridApi.setColumnDefs(this.columnDefs)
                this.gridApi.refreshHeader()
            } else {
                console.warn('Grid API is not available')
            }
        },
        confirmSettings() {
            this.showResendConfirmation = true
            this.showSettings = false
        },
        setCheckboxAttributes() {
            this.$nextTick(() => {
                if (this.$refs.showOnlyResendableCheckbox) {
                    const checkbox = this.$refs.showOnlyResendableCheckbox.$el.querySelector('.v-input__slot')
                    if (checkbox) {
                        checkbox.setAttribute('data-test', 'show-only-resendable-checkbox')
                    }
                }

                if (this.$refs.translateFormResendCheckbox) {
                    const checkbox = this.$refs.translateFormResendCheckbox.$el.querySelector('.v-input__slot')
                    if (checkbox) {
                        checkbox.setAttribute('data-test', 'translate-form-resend-checkbox')
                    }
                }

                if (this.$refs.requireResponseResendCheckbox) {
                    const checkbox = this.$refs.requireResponseResendCheckbox.$el.querySelector('.v-input__slot')
                    if (checkbox) {
                        checkbox.setAttribute('data-test', 'response-required-resend-checkbox')
                    }
                }
            })
        },
        clearSelections() {
            this.showSettings = false
            this.$refs.grid.gridApi.deselectAll()
            this.selections = []
            this.$refs.grid.gridOptions.api.refreshHeader()
        },
        onFilterDate(v) {
            this.filters.dates = v
            this.fetchData()
            this.fetchCounts()
        },
        fetchCounts() {
            const params = { templateIds: this.templateId }
            
            const dateFilters = this.filters.dates.included.map(x => x.value)
            if (dateFilters.length) {
                params.startDate = dateFilters[0].startDate
                params.endDate = dateFilters[0].endDate
            }

            const sentByFilter = this.filters.sentBy.included[0]
            if (sentByFilter) {
                params.userIds = sentByFilter.userId
            }

            this.$modelGet('guardianFormReportingCounts', params)
                .then(response => {
                    if(response.sent_list.length) {
                            
                            this.totalSent = response.sent_list[0].guardiansEmailed
                            this.delivered = response.sent_list[0].totalDelivered
                            this.undeliverable = Number(response.sent_list[0].undeliverable)
                            this.responseReceived = response.sent_list[0].responded
                            this.responseRequired = Number(response.sent_list[0].responseRequired)
                            this.noResponse = this.responseRequired - this.responseReceived
                    } else {
                        this.totalSent = 0
                        this.delivered = 0
                        this.undeliverable = 0
                        this.responseReceived = 0
                        this.responseRequired = 0
                        this.noResponse = 0
                    }
                })
                .catch(err => {
                    console.warn('Unable to load guardian form email counts', err)
                })
        },
        onFilterSentBy(v) {
            this.filters.sentBy = v
            this.fetchData()
            this.fetchCounts()
        },
        bulkDownload() {
            const bulkDownloadSelections = this.selections.map(selection => ({
                form_instance_id: selection.formInstanceId
            }))
            this.downloadFormZip(bulkDownloadSelections, null, false, null)
            this.clearSelections()
        },
        handleResendConfirmation() {
            this.showResendConfirmation = false
            this.clearSelections()
        },
        handleResendFailure() {
            this.showFailureModal = false
            this.clearSelections()
        },
        singleDownload(formData) {
            try {
                this.downloadFormZip([{form_instance_id: formData.formInstanceId}], null, false, null)
            } catch (error) {
                console.error('Error downloading form:', error)
            }
        },
        saveColumnVisibility() {
            if (!this.columnApi?.getAllColumns()) {
                return
            }

            const currentState = {}
            this.columnApi.getAllColumns().forEach(column => {
                if (column.getColId()) {
                    currentState[column.getColId()] = {
                        hide: !column.isVisible(),
                    }
                }
            })
            
            window.localStorage.setItem(`guardian-reporting-tab-${this.activeTab}-columns`, JSON.stringify(currentState))
        },

        restoreColumnVisibility() {
            if(this.columnApi) {
                try {
                    const savedState = JSON.parse(window.localStorage.getItem(`guardian-reporting-tab-${this.activeTab}-columns`) || '{}')
                    
                    const columnStates = this.columnDefs
                        .filter(colDef => colDef.field)
                        .map(colDef => ({
                            colId: colDef.field,
                            hide: savedState[colDef.field]?.hide ?? colDef.hide,
                        }))

                    this.columnApi.applyColumnState({
                        state: columnStates,
                        applyOrder: false
                    })
                } catch (error) {
                    console.warn('Error applying column state:', error)
                }
            }
        },
    },
    watch: {
        activeTab(newTab, oldTab) {
            if (oldTab !== undefined && this.columnApi.getAllColumns()) {
                const oldTabState = {}
                this.columnApi.getAllColumns().forEach(column => {
                    if (column.getColId()) {
                        oldTabState[column.getColId()] = {
                            hide: !column.isVisible(),
                        }
                    }
                })
                window.localStorage.setItem(`guardian-reporting-tab-${oldTab}-columns`, JSON.stringify(oldTabState))
            }
            
            if (this.$refs.grid?.gridApi) {
                this.clearSelections()
            }

            this.apiFailed = false
            
            this.$nextTick(() => {
                if (this.gridApi) {
                    this.gridApi.setColumnDefs(this.columnDefs)
                    
                    const savedState = JSON.parse(window.localStorage.getItem(`guardian-reporting-tab-${newTab}-columns`) || '{}')
                    const columnStates = this.columnDefs
                        .filter(colDef => colDef.field)
                        .map(colDef => ({
                            colId: colDef.field,
                            hide: savedState[colDef.field]?.hide ?? colDef.hide,
                        }))
                    
                    this.columnApi.applyColumnState({
                        state: columnStates,
                        applyOrder: false
                    })
                    
                    this.gridApi.refreshHeader()
                    this.gridApi.refreshCells({ force: true })
                }
            })

            this.fetchData()
            this.setCheckboxAttributes()
        },
        immediate: true
    },
    created() {
        window.addEventListener('beforeunload', this.saveColumnVisibility)
    },
    mounted() {
        this.$nextTick(() => {
            if (this.gridApi) {
                this.gridApi.setColumnDefs([])
                this.gridApi.setColumnDefs(this.columnDefs)
            }
            this.setCheckboxAttributes()
            this.fetchCounts()
        })
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.updateDataFromRoute(to)
        })
    },
    beforeRouteUpdate(to, from, next) {
        this.updateDataFromRoute(to)
        next()
    },
    deactivated() {
        if (this.columnApi) {
            this.saveColumnVisibility()
        }
    },
    beforeDestroy() {
        window.removeEventListener('beforeunload', this.saveColumnVisibility)
    },
}
</script>

<style scoped>
.guardian-reporting {
    position: relative;
    margin: 8px;
    padding: 20px;
}

.filter-btn {
    margin-left: -12px;
    margin-top: 20px;
}

.count-group {
    display: flex;
    align-items: center;
    padding: 16px;
    border: 1px solid #E0E1E6;
    border-radius: 5px;
    height: 94px;
}

.count-text {
    margin-left: 12px;
    margin-top: 18px;
}

.count-groups-container {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
}

.no-sent-data {
    margin-top: 20px;
    border: 1px solid #E0E1E6;
    border-radius: 5px;
    width: 100%;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.no-sent-data h5 {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 4px;
}

.count-info {
    display: flex;
    align-items: center;
}

.count-info i {
    margin-left: 4px;
    color: #495B8F;
}

.guardian-reporting-grid {
    height: 100%;
}

.guardian-reporting-grid ::v-deep .ag-header-cell, 
.guardian-reporting-grid ::v-deep .ag-cell {
    border-right: none !important;
}

.count {
    font-size: 20px;
    font-weight: bold;
}

.checkbox-label {
    font-size: 14px;
}

.background-default {
    background: #66A7BC;
}

.selection-toolbar {
    width: 98%;
    position: absolute;
    left: 0px;
    height: 48px;
    color: white;
    z-index: 10;
    border-radius: 4px;
}


.background-default {
    background: #006C90;
}

.selected-count {
    margin-left: 16px;
    color: white;
}

.selection-toolbar-btn {
    margin-right: 20px;
    margin-left: 20px;
}

.left-align-content {
    text-align: left;
}

.right-align-btns {
    display: flex;
    justify-content: flex-end;
}
</style>