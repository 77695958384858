<template>
    <fe-card
        class="alt-card ma-3"
        :headerText="today"
    >
        <template v-slot:title-toolbar>
            <v-menu
                ref="date-picker"
                offset-y
                left
            >
                <template v-slot:activator="{on}">
                    <v-btn icon x-small v-on="on" data-test="task-list-date-picker"><v-icon>far fa-calendar-day</v-icon></v-btn>
                </template>
                <v-date-picker
                    :value="windowDate"
                    @input="setDate"
                    :color="$vuetify.theme.themes.light.primary.base"
                />
            </v-menu>
        </template>
        <template v-slot:body>
            <div v-if="useNewLaunchPad" style="height: 200px">
                <div
                    v-for="(item, i) in items"
                    class="my-4"
                    :key="`today-item-${i}`"

                >
                    <div class="d-flex list-text" @click="itemClicked(item)" :data-test="item.dataTest">
                        <v-icon class="mr-3">{{item.icon}}</v-icon>
                        <div class="text-col">
                            <div>{{item.count}} {{item.count == 1 ? item.singleName : item.name}}</div>
                            <div class="text-description" v-if="item.description">{{item.description}}</div>
                        </div>
                        <v-spacer/>
                        <v-icon>fas fa-chevron-right</v-icon>
                    </div>
                </div>
            </div>
            <div v-else>
                <div
                v-for="(item, i) in items"
                class="my-4"
                :key="`today-item-${i}`"
            >
                <div class="d-flex list-text" @click="itemClicked(item)" :data-test="item.dataTest">
                    <v-icon class="mr-3">{{item.icon}}</v-icon>
                    <div class="text-col">
                        <div>{{item.count}} {{item.count == 1 ? item.singleName : item.name}}</div>
                        <div class="text-description" v-if="item.description">{{item.description}}</div>
                    </div>
                    <v-spacer/>
                    <v-icon>fas fa-chevron-right</v-icon>
                </div>
            </div>
            </div>

            <fe-dialog
                v-if="selectingWindow"
                @dismiss="selectingWindow = false"
                @close="selectingWindow = false"
                @accept="openSelectedWindow"
                title="Select Assessment"
                dismissButtonText="Cancel"
                acceptButtonText="Continue"
                :acceptButtonDisabled="!selectedWindowId"
            >
                <div class="d-flex flex-column">
                    <v-flex>
                        <fe-label>Assessments With Open Windows</fe-label>
                        <v-select
                            v-model="selectedWindowId"
                            flat solo dense
                            :items="formattedOpenWindows"
                            itemText="name"
                            itemValue="id"
                            clearable
                        >
                            <template v-slot:item="{ item, index }">
                                <div style="width: 100%; margin-top: 4px; margin-bottom: 4px;">
                                    {{item.data_point_type_name}} - {{ item.name }}<br />
                                    <span class='text-smaller'>Open from {{ item.start_date }}&mdash;{{ item.end_date }}</span><br />
                                    <span class='text-smaller'>{{ item.school_display }} | {{ item.grade_display }}</span>
                                </div>
                            </template>
                            <template v-slot:selection="data">
                                {{data.item.data_point_type_name}} - {{ data.item.name }}
                            </template>
                        </v-select>
                    </v-flex>
                </div>
                <fe-crud
                    autoload
                    :config="$models.assessmentWindow"
                    :readParams="{
                        active: 1,
                        as_of_date: windowDate
                    }"
                    @read="openWindows = $event"
                />
            </fe-dialog>
        </template>
    </fe-card>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: 'TodayCard',
        computed: {
            ...mapState('global', ['sessionUser', 'currentYear', 'defaultSchool', 'lastLaunchpadVisit']),
            today() {
                let options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
                let dt = new Date(this.date)
                return dt.toLocaleDateString("en-US", options)
            },
            windowDate() {
                return this.$dayjs(this.date).format('YYYY-MM-DD')
            },
            formattedOpenWindows() {
                return this.openWindows.map(itm => {
                    return Object.assign({}, itm, {
                        school_display: (itm.school_id.length > 1)
                            ? `${itm.school_id.length} Schools`
                            : itm.school_display_name,
                        grade_display: (itm.grade_id.length > 1)
                            ? `${itm.grade_id.length} Grades`
                            : itm.grade_display_name
                    })
                })
            },
            filteredItems() {
                return this.items.filter(x => x.key === 'actionitems' || x.count)
            },
            useNewLaunchPad() {
                return this.$store.getters['flags/flags']['e-c-split-viz']
            }
        },
        data () {
            return {
                reloading: false,
                date: undefined,
                selectingWindow: false,
                selectedWindowId: null,
                openWindows: [],
                openIncidents: [],
                items: [{
                    key: 'windows',
                    name: 'Open Assessment Windows',
                    singleName: 'Open Assessment Window',
                    count: 0,
                    icon: 'far fa-edit',
                    description: 'These are assessments ready for scores to be entered',
                    dataTest: 'open-assessment-windows'
                }, {
                    key: 'actionitems',
                    name: 'Action Items',
                    singleName: 'Action Item',
                    route: '/ActionItems',
                    count: 0,
                    icon: 'far fa-tasks',
                    dataTest: 'action-items'
                }, {
                    key: 'incidents',
                    name: 'Open Incidents',
                    singleName: 'Open Incident',
                    component: 'open-incidents',
                    count: 0,
                    icon: 'far fa-whistle',
                    dataTest: 'open-incidents'
                }, {
                    key: 'meetings',
                    name: 'Meetings Scheduled',
                    singleName: 'Meeting Scheduled',
                    route: '/Meetings',
                    count: 0,
                    icon: 'far fa-calendar',
                    stacked: 'fas fa-user',
                    dataTest: 'meetings-scheduled'
                }, {
                    key: 'ending_interventions',
                    name: 'Ending Interventions',
                    singleName: 'Ending Intervention',
                    count: 0,
                    icon: 'far fa-hands-heart',
                    dataTest: 'ending-interventions'
                }]
            }
        },
        watch: {
            lastLaunchpadVisit(v) {
                if (!this.reloading) {
                    this.reloading = true
                    this.loadData()
                }
            },
        },
        mounted() {
            this.date = this.$dayjs()
            this.loadData()
        },
        methods: {
            loadData() {
                this.loadTasks()
                this.loadOpenIncidents()
                this.loadDateDependentData()
            },
            loadDateDependentData() {
                this.loadMeetings()
                this.loadWindows()
                this.loadInterventions()
            },
            loadTasks() {
                let p = {
                    assignee_id: this.sessionUser.user.id,
                    task_status_id: [1,2]
                }
                this.$axios.get(this.$models.getUrl('task', 'read') + '&' + this.$objectToParams(p))
                    .then(response => {
                        let data = response.data.tasks || []
                        this.setCount('actionitems', data.length)
                    })
            },
            loadOpenIncidents() {
                let p = {
                    open: 1,
                    school_id: this.defaultSchool?.id,
                }
                this.$axios.get(this.$models.getUrl('studentIncidentList', 'read') + '&' + this.$objectToParams(p))
                    .then(response => {
                        let totalCount = response.data.totalCount
                        this.openIncidents = response.data.incidents
                        this.setCount('incidents', totalCount)
                    })
            },
            loadMeetings() {
                this.$axios.get('dashboard.php?action=get&property=home&schedule_date_occurrence=' + this.windowDate)
                    .then(response => {
                        let data = response.data.dashboard.meetings || []
                        this.setCount('meetings', data.length)
                    })
            },
            loadInterventions() {
                let startDate = this.$dayjs(this.date).format('YYYY-MM-DD')
                let endDate = this.$dayjs(this.date).add(14,'d').format('YYYY-MM-DD')
                let params = {
                    active: 1,
                    include_students: 1,
                    user_id: this.sessionUser.user.id,
                    school_year_id: this.currentYear.id,
                    student_date_range_start: startDate,
                    student_date_range_end: endDate
                }
                this.$axios.get(this.$models.getUrl('interventionPlanList', 'read') + '&' + this.$objectToParams(params))
                    .then(response => {
                        let interventions = []
                        response.data.plans.forEach(plan => {
                            if (plan.students) {
                                let data = plan.students.map(s => {
                                    s.intervention_plan_name = plan.name
                                    return s
                                })
                                interventions.push(...data)
                            }
                        })

                        this.setCount('ending_interventions', interventions.length)
                        this.reloading = false // might be another promise still doing something, but this is approximately the end
                    })
            },
            loadWindows() {
                let url = this.$models.getUrl('assessmentWindow', 'read')
                let params = {
                    active: 1,
                    property: 'count',
                    as_of_date: this.windowDate
                }
                this.$axios.get(url, { params: params }).then(response => {
                    let count = response.data.count || 0
                    this.setCount('windows', count)
                })
            },
            setCount (key, count) {
                this.items.find(itm => itm.key == key).count = count
            },
            getCount (key) {
                return this.items.find(itm => itm.key == key).count
            },
            itemClicked(item) {
                switch (item.key) {
                    case 'windows':
                    this.selectedWindowId = null
                    this.selectingWindow = true
                        break;
                    case 'incidents':
                        this.$store.commit('global/addDockableWindow', {
                            name: 'Open Incidents',
                            component: 'open-incidents',
                            attrs: {
                                rowData: this.openIncidents
                            }
                        })
                        break;
                    case 'ending_interventions':
                        this.$store.commit('global/addDockableWindow', {
                            name: 'Ending Interventions',
                            component: 'ending-interventions',
                            attrs: {
                                date: this.date
                            }
                        })
                        break;
                    default:
                        if (item.route) {
                            this.$router.push(item.route)
                        }
                }
            },
            openSelectedWindow () {
                this.selectingWindow = false
                this.$store.commit('global/addDockableWindow', {
                    name: 'Enter Assessment Scores',
                    component: 'score-editor',
                    attrs: { window: this.openWindows.find(itm => itm.id == this.selectedWindowId) }
                })
            },

            setDate(v) {
                let current = this.date.format('Y-MM-DD')
                if (current !== v) {
                    this.date = this.$dayjs(v)
                    this.loadDateDependentData()
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .list-text {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;
        cursor: pointer;

        .text-col {
            .text-description {
                font-size: 80%;
            }
        }

        &:hover {
            font-weight: bold;
        }
    }
</style>
