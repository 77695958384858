<template>
    <fe-card
        class="alt-card mx-2"
        ref="enrollmentCard"
    >
        <template v-slot:title-text>
            <span class="mr-5 d-inline-block fe-card-title-text" style="font-size: 16px">
                Enrollment
            </span>
            <fe-info-tip
                color="#7e8494! important"
                icon="fal fa-info-circle"
                style="float: right; margin-top: 5px"
                tooltip="Enrollment data is provided by your Student Information System (SIS) and syncs with eduCLIMBER each night."
            >
            </fe-info-tip>
        </template>

        <template v-slot:title-toolbar>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn text icon data-test="enrollment-menu-btn">
                        <v-icon v-on="on">more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn style="padding: 0" text @click="printChart" data-test="enrollment-print-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br/>
                        <v-btn style="padding: 0" text @click="exportChart" data-test="enrollment-download-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </template>

        <template v-slot:body>
            <div v-if="params" id="enrollment-data-viz" class="enr-card-body pt-3" style="height: 350px">
                <div class="d-flex enr-card-details flex-grow-1">
                    <div class="enr-total">
                        <div class="enr-total-num" data-test="enrollment-yesterday-num" @click="enrollmentClicked" style="cursor: pointer;">{{ totalEnrDisplay }}</div>
                        <div class="enr-total-text">yesterday's enrollment</div>
                    </div>

                    <v-spacer/>

                    <div class="enr-changes">
                        <div v-if="fromLastYear.num != 0" class="d-flex changes-row">
                            <div class="changes-value" data-test="enrollment-last-year-num" @click="enrollmentClicked">
                                <v-icon class="changes-caret">{{ `fa-caret-${fromLastYear.delta}` }}</v-icon>
                                {{ fromLastYear.num }}
                            </div>
                            <div class="flex-grow-1 changes-category">
                                from this time last year
                            </div>
                        </div>
                        <div v-else class="d-flex changes-row">
                            <div class="changes-value" data-test="enrollment-last-year-num" @click="enrollmentClicked">
                                <div >{{ fromLastYear.num }}</div>
                            </div>
                            <div class="flex-grow-1 changes-category">
                                no change from last year
                            </div>
                        </div>

                        <div v-if="fromLastMonth.num != 0" class="d-flex changes-row">
                            <div class="changes-value" data-test="enrollment-last-month-num" @click="enrollmentClicked">
                                <v-icon class="changes-caret">{{ `fa-caret-${fromLastMonth.delta}` }}</v-icon>
                                {{ fromLastMonth.num }}
                            </div>
                            <div class="flex-grow-1 changes-category" style="width: 130px;">
                                from this time last month
                            </div>
                        </div>
                        <div v-else class="d-flex changes-row">
                            <div class="changes-value" data-test="enrollment-last-month-num" @click="enrollmentClicked">
                                <div>{{ fromLastMonth.num }}</div>
                            </div>
                            <div class="flex-grow-1 changes-category">
                            no change from last month
                            </div>
                        </div>
                    </div>
                </div>

                <highcharts
                    ref="enrollmentChart"
                    class="enr-chart"
                    :options="chartOptions"
                />

                <fe-btn
                    @click="enrollmentClicked"
                    usage="secondary"
                    style="float: right; margin-top: 50px"
                    data-test="enrollment-explore-btn"
                >
                    Explore
                </fe-btn>
            </div>
        </template>

    </fe-card>
</template>

<script>
import {mapState} from "vuex";
import html2canvas from 'html2canvas';
import Printd from 'printd';

export default {
    name: 'EnrollmentCard',

    data() {
        return {
            ready: false,
            params: null,
            config: null
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'defaultSchool']),

        totalEnrDisplay() {
            return new Intl.NumberFormat().format(this.params?.total_enrolled)
        },

        fromLastYear() {
            return this.display(this.params.from_this_time_last_year_enrolled)
        },

        fromLastMonth() {
            return this.display(this.params.from_last_month_enrolled)
        },

        schoolUrl() {
            return 'schools.php?action=get&school_year_id=' + this.currentYear.id
        },

        chartOptions() {
            let categories = this.params?.charts?.map(x => {
                let y = x.school_year
                return y.slice(0,5) + y.slice(7)
            })

            let me = this
            let series = [{
                name: 'Enrolled Students',
                data: this.params?.charts?.map(x => x.student_count),
                color: '#006c90',
                cursor: 'pointer',
                point: {
                    events: {
                        click: function (event) {
                            me.enrollmentClicked()
                        }
                    }
                }
            }]

            return {
                chart: {
                    events: {
                        load: function() {
                            const series = this.series[0]
                            series.group.element.setAttribute('data-test', 'enrollment-summary-line')
                        }
                    },
                    height: '170px'
                },
                credits: {
                    enabled: false
                },
                navigation: {
                    buttonOptions: {
                        enabled: false
                    }
                },
                title: {
                    text: '',
                },
                xAxis: {
                    categories,
                    title: {
                        text: undefined
                    },
                },
                yAxis: {
                    title: {
                        text: undefined
                    },
                    labels: {
                        formatter: x => new Intl.NumberFormat().format(x.value)
                    }
                },
                legend: {
                    enabled: false
                },
                plotOptions: {
                    line: {
                        marker: {
                            enabled: false
                        }
                    }
                },
                series
            }
        }
    },

    mounted() {
        this.loadEnrollment()
    },

    methods: {

        loadEnrollment() {
            let url = this.$models.getUrl('enrollmentSummary', 'read');

            return this.$axios.get(url)
                .then(response => {
                    if (response?.data.summary) {
                        this.params = response.data.summary
                        this.ready = true
                    } else {
                        this.ready = false
                    }
                })
        },

        display(value) {
            let delta = value < 0 ? 'down' : 'up'

            return {
                num: new Intl.NumberFormat().format(Math.abs(value)),
                delta,
            }
        },

        enrollmentClicked() {
            this.$dockableWindow({
                name: 'Enrollment',
                component: 'enrollment-reporting',
                attrs: {
                    params: {}
                }
            })
        },

        printChart() {
            html2canvas(document.querySelector('#enrollment-data-viz')).then(canvas => {
                const imgURL = canvas.toDataURL('image/jpeg')
                const image = document.createElement('img')
                image.src = imgURL
                image.setAttribute("id", 'print-content')
                document.body.appendChild(image)
                const printD = new Printd();
                printD.print(document.getElementById('print-content'))
                document.body.removeChild(image)
            })
        },

        exportChart() {
            let enrollmentDiv = document.querySelector('#enrollment-data-viz')
            enrollmentDiv.classList.add('download-padding')

            html2canvas(enrollmentDiv).then(canvas => {
                const link = document.createElement('a')
                link.setAttribute('download', 'Enrollment-Summary.png')
                link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
                link.click()
            })
            setTimeout(() => {enrollmentDiv.classList.remove('download-padding')}, 20)
        }
    }
}
</script>

<style scoped lang="scss">

.enr-card-body {

    .enr-card-details {
        height: 70px;

        .enr-total {
            min-width: 90px;
            color: var(--v-primary-base);
            font-size: 24px;
            line-height: 31px;
            text-align: left;
        }

        .enr-total-num {
            font-weight: 600;
        }

        .enr-total-text {
            text-align: left;
            font-size: 14px;
            font-weight: unset;
            color: #828795;
        }

        .enr-changes {
            width: 240px;
            color: var(--v-primary-base);

            .changes-row {
                height: 25px;

                .changes-value {
                    text-align: right;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 20px;
                    padding-right: 8px;
                    width: 86px;
                    cursor: pointer;

                    .changes-caret {
                        font-size: 20px;
                        color: inherit !important;
                    }
                }

                .changes-category {
                    text-align: left;
                    font-size: 12px;
                    color: #828795;
                }
            }
        }
    }

    .enr-chart {
        height: 170px;
    }
}

.download-padding {
    padding-left: 5px;
}
</style>
