<template>
    <div class="restricted-border">
        <div class="center-SIS-not-enabled-text">
            <v-icon class="far fa-search fe-icon" style="color: #687baa; font-size: 32px; padding: 18px;" ></v-icon>
            <div :style="{'font-weight': 'bold', 'padding': '8px'}">
                Chronic absence data unavailable
            </div>
            <div>
                To use this report, you must first enable the SIS
            </div>
            <div>
                Reported Attendance rate in your system settings.
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        name: 'Restricted',
    }
</script>

<style lang="scss">
.restricted-border{
    height: 100%;
    width: 95%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid #dddddd;
}
.center-SIS-not-enabled-text {
    height: 100%;
    margin-top: 10%;
    margin-bottom: 10%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center !important;
}
</style>
