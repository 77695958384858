<template>
    <fe-card
        class="alt-card ma-3"
    >
        <template v-slot:title-text>
            <span class="mr-5 d-inline-block fe-card-title-text" style="font-size: 16px">
                Attendance
            </span>
            <!-- commenting until product gets copy for tooltip text -->
            <!-- <fe-info-tip
                color="#7e8494! important"
                icon="fal fa-info-circle"
                style="float: right; margin-top: 5px"
                tooltip=""
            >
            </fe-info-tip> -->
        </template>

        <template v-slot:title-toolbar>
            <v-menu class="flex-shrink-1" offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn text icon data-test="attendance-menu-btn">
                        <v-icon v-on="on">more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn style="padding: 0" text @click="printChart" data-test="attendance-print-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br/>
                        <v-btn style="padding: 0" text @click="exportChart" data-test="attendance-download-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </template>

        <template v-slot:body>
            <div style="height: 400px;" >
                <attendance-chart
                    v-if="att.ready"
                    ref="launchpad-attendance-chart"
                    :height="280"
                    :config="att.config"
                    :chartParams="att.params"
                    :dataTestName="att.dataTestName"
                    :showInsight="false"
                    :pinnable="false"
                    :collectionChart="false"
                    :menu="false"
                    minimal
                    @chartClicked="attendanceClicked"
                />

                <div
                    v-else
                    style="cursor: pointer; vertical-align: middle; height: 280px;"
                    @click="attendanceClicked"
                    data-test="visualizations-attendance-search"
                >
                    <fe-empty-state  icon="fa-search" text="No data found.  Click to search"/>
                </div>

                <fe-btn
                    v-if="att.ready"
                    @click="attendanceClicked"
                    usage="secondary"
                    style="float: right; margin-top: 70px;"
                    data-test="attendance-explore-btn"
                >
                    Explore
                </fe-btn>
            </div>
        </template>
    </fe-card>
</template>

<script>
import {mapState} from 'vuex'
import AttendanceChart from '@/components/charts/AttendanceChart'
import windowOptionsMixin from '@/mixins/windowOptions'

export default {
    name: 'VisualizationsCard',

    components: {
        AttendanceChart,
    },

    mixins: [windowOptionsMixin],

    data() {
        return {
            tools: [],
            att: {
                ready: false,
                params: null,
                config: null
            },
            endDate: undefined,
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'sessionUser', 'defaultSchool']),

        startDate() {
            if (!this.endDate) return undefined
            return this.$dayjs(this.endDate).subtract(14, 'd').format('YYYY-MM-DD')
        },

        hasRestrictedIncidentTypes() {
            return (!!this.sessionUser.user?.has_restricted_incident_types)
        },

        attendanceChart() {
            return this.$refs['launchpad-attendance-chart'].$refs['attendanceChart']
        }
    },

    watch: {
        endDate(n, o) {
            if (o && n && n !== o) {
                this.loadAttendance()
            }
        },
    },

    mounted() {
        this.endDate = this.$dayjs().format('YYYY-MM-DD')
        this.loadAttendance()
    },

    methods: {
        loadAttendance() {
            let url = this.$models.getUrl('attendanceCharts', 'read');
            let p = {
                chart_type: ['month'],
                school_year_id: this.currentYear.id,
                school_id: this.defaultSchool?.id,
            }

            return this.$axios.get(url + '&' + this.$objectToParams(p))
                .then(response => {
                    let data = this.$ecResponse(response, 'charts')

                    data.forEach(chart => {
                        this.att.params = chart.params
                        this.att.config = chart
                        this.att.dataTestName = 'visualizations-attendance-chart'
                        if (chart.data.length > 0) this.att.ready = true
                    })
                })
        },

        attendanceClicked() {
            this.$dockableWindow({
                name: 'Attendance Reporting',
                component: 'attendance-reporting',
                attrs: {
                    params: {
                        school_year_id: this.currentYear.id,
                        school_id: this.defaultSchool?.id,
                    },
                    dataTestParentName: 'launchapad'
                }
            })
        },

        printChart() {
            this.attendanceChart.printChart()
        },

        exportChart() {
            this.attendanceChart.exportChart({
                    type: 'image/png',
                    filename: 'Attendance Summary'
                })
        }

    }
}
</script>

<style lang="scss" scoped>
</style>
