<template>
    <fe-card
        class="alt-card mx-2"
        ref="chronicAbsenseCard"
    >
        <template v-slot:title-text>
            <span class="mr-5 d-inline-block fe-card-title-text" style="font-size: 16px">
                Chronic Absence
            </span>
            <fe-info-tip
                color="#7e8494 !important"
                icon="fal fa-info-circle"
                style="float: right; margin-top: 5px"
                tooltip="Chronic absence is when a student is absent (excused or unexcused) for at least 10% of the days. The overall rate indicates the percentage of students who are chronically absent in a given time period."
            >
            </fe-info-tip>
        </template>

        <template v-slot:title-toolbar>
            <v-menu offset-y left>
                <template v-slot:activator="{ on }">
                    <v-btn text icon data-test="chronic-absence-menu-btn">
                        <v-icon v-on="on">more_vert</v-icon>
                    </v-btn>
                </template>

                <slot name="menu">
                    <v-card flat style="background: #fff; padding: 10px">
                        <slot name="extra-menu-items"></slot>
                        <v-btn style="padding: 0" text @click="printChart" data-test="chronic-absence-print-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-print</v-icon>
                            Print PDF
                        </v-btn>
                        <br/>
                        <v-btn style="padding: 0" text @click="exportChart" data-test="chronic-absence-download-btn">
                            <v-icon class="mr-2" style="color:rgba(0, 0, 0, 0.54)">fa-download</v-icon>
                            Download Image
                        </v-btn>
                    </v-card>
                </slot>
            </v-menu>
        </template>

        <template v-slot:body >
            <div class="ca-body-container" style="height: 350px">
                <div id="ca-data-viz" class="center-ca-data" v-if="ready">
                    <div v-if="!error && !unavailable" class="ca-percentage">
                        {{ chronicallyAbsentPercentage }}%
                    </div>
                    <div v-if="error || unavailable" class="ca-no-data">
                        <v-icon size="55">fal fa-horizontal-rule</v-icon>
                    </div>
                    <div class="ca-text">
                        Students chronically absent this school year
                    </div>
                    <div v-if="percentChange !== 0 && !error && !unavailable" id="ca-change-container">
                        <div id="ca-percent-change">
                            <v-icon class="ca-change">{{ `fa-arrow-${displayUpDownArrow}` }}</v-icon>
                            {{ displayPercentChange }}%
                        </div>
                        <div id="ca-percent-change-text">
                            pts from this time last year
                        </div>
                    </div>
                    <div v-if="percentChange === 0 && !error && !unavailable" id="ca-change-container">
                        <div id="ca-percent-change-text">
                            No change since this time last year
                        </div>
                    </div>
                    <div v-if="error" id="ca-change-container">
                        <v-icon style="float: left; padding-right: 4px;" color="#bf0f00 !important">far fa-exclamation-circle</v-icon>
                        <div style="color: #bf0f00; float: right;">Data temporarily unavailable</div>
                    </div>
                    <div v-if="unavailable" id="ca-change-container">
                        <v-icon style="float: left; padding-right: 4px;" color="#136eb3 !important">fal fa-info-circle</v-icon>
                        <div style="float: right; color:#136eb3">Attendance data not yet available</div>
                    </div>

                </div>

                <fe-btn
                    @click="caExploreClicked"
                    usage="secondary"
                    class="ca-explore-btn"
                    style="float: right;"
                    data-test="chornic-absence-explore-btn"
                >
                    Explore
                </fe-btn>
            </div>
        </template>
    </fe-card>
</template>

<script>
import html2canvas from 'html2canvas'
import Printd from 'printd'

export default {
    name: 'ChronicAbsenceCard',

    data() {
        return {
            chronicallyAbsentPercentage: null,
            percentChange: null,
            error: false,
            unavailable: false,
            ready: false
        }
    },

    computed: {
        displayUpDownArrow() {
            return this.percentChange < 0 ? 'down' : 'up'
        },
        displayPercentChange() {
            return this.percentChange.toString().includes('-') ? this.percentChange.toString().substring(1) : this.percentChange
        }
    },

    mounted() {
        this.loadCAData()
    },

    methods: {
        loadCAData() {
            this.$axios.get(this.$models.getUrl('chronicAbsenceSummary','read')).then(response => {
                if (response.status !== 200) {
                    this.error = true
                    this.ready = true
                    return
                }
                if (response.data.chronically_absent_percentage === null && response.data.percent_change === null) {
                    this.unavailable = true
                    this.ready = true
                    return
                }
                this.chronicallyAbsentPercentage = response.data.chronically_absent_percentage,
                this.percentChange = response.data.percent_change
                this.error = false,
                this.unavailable = false
                this.ready = true
            })
        },

        printChart() {
            html2canvas(document.querySelector('#ca-data-viz'), {width: 680, height: 500, x: -130, y: -60}).then(canvas => {
                const imgURL = canvas.toDataURL('image/jpeg')
                const image = document.createElement('img')
                image.src = imgURL
                image.setAttribute("id", 'print-content')
                document.body.appendChild(image)

                const printD = new Printd();
                printD.print(document.getElementById('print-content'))
                document.body.removeChild(image)
            })
        },

        exportChart() {
            let caDiv = document.querySelector('#ca-data-viz')
            caDiv.classList.add('download-padding')

            html2canvas(caDiv).then(canvas => {
                const link = document.createElement('a')
                link.setAttribute('download', 'Chronic-Absense-Summary.png')
                link.setAttribute('href', canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream'))
                link.click()
            })
            setTimeout(() => {caDiv.classList.remove('download-padding')}, 20)
        },

        caExploreClicked() {
            this.$router.push({path: 'ChronicAbsence'})
        }
    }
}
</script>

<style scoped lang="scss">

.ca-body-container {
    display: grid;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-evenly;

    .center-ca-data {
        text-align: center;

        .ca-percentage {
            margin: 5px;
            font-size: 64px;
            font-weight: 700;
        }

        .ca-no-data {
            color: #495b8f;
        }

        .ca-text {
            margin: 5px;
            padding-top: 24px;
            font-size: 16px;
            color: #495b8f;
        }

        .ca-change {
            color: #495b8f !important;
        }

        #ca-change-container {
            margin-left: auto;
            margin-right: auto;
            max-width:fit-content;
            font-size: 16px;
            color: #495b8f;
        }

        #ca-percent-change {
            float: left;
            padding-right: 4px;
        }

        #ca-percent-change-text {
            float: right;
        }
    }

    .ca-explore-btn{
        position: absolute;
        bottom: 16px;
        right: 16px;
    }
}

.download-padding {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 20px;
    padding-bottom: 10px;
}
</style>
