<template>
    <div class="d-flex flex-column flex-fill" style="padding: 26px;">
        <div class="d-flex pt-5 px-5 pb-1" style="padding-bottom: 24px !important;">
            <span style="font-size: 24px; padding-right: 10px">Chronic Absence</span>
            <fe-info-tip
                color="#495B8F !important"
                icon="fal fa-info-circle"
                size="18px"
                style="align-self: center;"
                tooltip="copy to come"
            >
            </fe-info-tip>
        </div>

        <div :key="caPageReload">
            <div v-if="prefs.sisReportedConfigured" >
                <div class="d-flex" style="padding-left: 4px !important; padding-bottom: 12px !important;">
                    <fe-filter-btn
                        v-model="filters.school_id"
                        ref="ca-school-filter"
                        rootProperty="schools"
                        title="Schools"
                        :url="schoolUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('school')"
                        @apply="apply('ca-school-filter')"
                        data-test="ca-school-filter-btn"
                    />
                    <fe-filter-btn
                        v-model="filters.grade_id"
                        ref="ca-grade-filter"
                        rootProperty="grades"
                        title="Grade Level"
                        :url="gradeUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('grade')"
                        @apply="apply('ca-grade-filter')"
                        data-test="ca-grade-level-filter-btn"
                    />
                    <fe-filter-btn
                        v-model="filters.ethnicity"
                        ref="ca-ethnicity-filter"
                        rootProperty="ethnicities"
                        itemValue="ethnicity"
                        itemText="display_name"
                        title="Race/Ethinicity"
                        :url="ethnicityUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('ethnicity')"
                        @apply="apply('ca-ethnicity-filter')"
                        data-test="ca-ethnicity-filter-btn"
                    />
                    <fe-filter-btn
                        v-model="filters.gender"
                        ref="ca-gender-filter"
                        rootProperty="genders"
                        itemValue="gender"
                        itemText="display_name"
                        title="Gender"
                        :url="genderUrl"
                        multiple
                        showFirstSelection
                        showClearButton
                        showApplyButton
                        @clear="clearFilter('gender')"
                        @apply="apply('ca-gender-filter')"
                        data-test="ca-gender-filter-btn"
                    />
                    <more-filters ref="ca-more-filters" v-model="demoFilters" @apply="applyMore"></more-filters>
                </div>

                <div>
                    <fe-tabs
                        class="ca-tabs"
                        id="ca-page-tabs"
                        :tabs="tabs"
                        @tabClicked="tabClicked"
                    />
                </div>

                <v-container
                    v-if="activeTab.show === tab.show"
                    v-for="(tab, index) in tabs"
                    fluid
                    class="chart-area"
                    :key="`tab-${index}`"
                >

                    <chronic-absence-rate-chart
                        ref="ca-rate-chart"
                        :data="tab"
                        :year="selectedTimePeriod"
                        :ready="true"
                        @change="chartParamChange"
                        @clearAllFilters="clearAllFilters"
                    />
                </v-container>
            </div>

            <div v-else>
                <restricted/>
            </div>
        </div>

        <fe-crud ref="prefsCrud" :config="prefsCrudConfig" @read="prefsFetched" autoload/>
    </div>

</template>

<script>
import {mapState} from "vuex";
import MoreFilters from "@/components/modules/enrollmentReporting/MoreFilters";
import ChronicAbsenceRateChart from "../../charts/ChronicAbsenceRateChart.vue";
import baseConfig from "../../charts/chartFormats/baseConfig";
import { singleSeriesPattern, twoSeriesPatterns, fiveSeriesPatterns } from "../../charts/chartFormats/patterns/seriesPatterns";
import NoFilterResults from '../../charts/emptyStateMessaging/NoFilterResults.vue';
import Restricted from "./messaging/Restricted.vue";


export default {
    name: 'ChronicAbsenceReporting',

    components: {
        MoreFilters,
        ChronicAbsenceRateChart,
        NoFilterResults,
        Restricted
    },

    data() {
        return {
            prefs: {},
            caPageReload: 0,
            activeTab: {
                name: 'Overall',
                show: 'district',
                path: '#'
            },
            tabData: {
                district: {data: undefined},
                school: {data: undefined},
                grade: {data: undefined},
            },
            selectedTimePeriod: {
                name: 'Last 2 Years (Monthly)',
                id: '-2'
            },
            tempData: [{
                    name: '2023-2024',
                    data: [5.2, 7.3, 18.1, 16.5, 4.9, 19.2, 3.8],
                },
                {
                    name: '2025-2026',
                    data: [4.5, 6.5, 17.5, 6.0, 4.3, 18.6, 3.2],
                },

                {
                    name: '2026-2027',
                    data: [14.2, 6.1, 17.2, 5.8, 4.0, 8.3, 3.0],
                },
                {
                    name: '2027-2028',
                    data: [3.9, 5.7, 7.0, 5.6, 3.7, 8.0, 2.8],
                },
                {
                    name: '2024-2025',
                    data: [4.8, 6.9, 17.8, 6.2, 4.6, 8.9, 3.4],
                },
            ],
            seriesNumToShow: 2,
            filters: {
                school_id: undefined,
                grade_id: undefined,
                ethnicity: undefined,
                gender: undefined
            },
            demoFilters: {},
            countsToggle: true
        }
    },

    computed: {
        ...mapState('global', ['currentYear', 'districtPreferences']),

        tabs() {
            return [{
                name: 'Overall',
                show: 'district',
                config: this.districtConfig,
                path: '#',
            },
            {
                name: 'By Shcool',
                show: 'school',
                config: {},
                path: '#',
            },
            {
                name: 'By Grade Level',
                show: 'grade',
                config: {},
                path: '#',
            },
            {
                name: 'By Race/Ethnicity',
                show: 'ethnicity',
                config: {},
                path: '#',
            }]
        },

        prefsCrudConfig() {
            let cfg = _.cloneDeep(this.$models.preferences)
            cfg.read.params.property = 'district'
            return cfg
        },

        schoolUrl() {
            return 'schools.php?action=get&school_year_id=' + this.currentYear.id
        },

        gradeUrl() {
            return 'grades.php?action=get&school_year_id=' + this.currentYear.id
        },

        ethnicityUrl() {
            return 'filters.php?action=get&filter=ethnicity'
        },

        genderUrl() {
            return 'filters.php?action=get&filter=gender'
        },

        districtConfig() {
            let caConfig = this.$_.cloneDeep(this.baseConfig)

            if (this.tabData.district.data) {
                caConfig.series = this.tabData.district.data.map(d => {
                    return {
                        name: d.name,
                        data: d.data
                    }
                })
            }

            caConfig.xAxis.categories = ['Harborview School', 'Meadowbook Primary School', 'Oakwood Elementary', 'Parkside Middle School', 'Pine Hill Academy', 'Riverside High School', 'Springfield Elementary']

            let patterns = caConfig.series.length === 1 ? singleSeriesPattern : caConfig.series.length === 2 ? twoSeriesPatterns : fiveSeriesPatterns
            caConfig.series.forEach((series, index) => {
                series.color = patterns[index]
                if (patterns[index].patternIndex) series.color.borderColor = '#8085e9'
            })
            caConfig.legend.enabled = this.seriesNumToShow > 1 ? true : false
            if (this.seriesNumToShow > 1) {
                caConfig.legend.enabled = true
            } else {
                caConfig.legend.enabled = false
                caConfig.xAxis.title.text = `June ${this.currentYear.name.slice(0, 4)} - February ${this.currentYear.name.slice(5, this.currentYear.name.length)}`
                caConfig.xAxis.title.offset = 70
            }

            return caConfig
        },

        baseConfig() {
            let chartConfig = baseConfig
            chartConfig.chart.type = 'column'
            chartConfig.yAxis.title.text = 'Chronic Absence Rate (%)'
            chartConfig.legend.symbolRadius = 0
            chartConfig.yAxis.labels.formatter = function() { return new Intl.NumberFormat().format(this.value) + ' %' }
            chartConfig.plotOptions.column = {
                dataLabels: {
                    enabled: this.countsToggle,
                    style: {
                        fontSize: 10,
                        color: '#495b8f',
                    },
                    formatter : function() {
                        return this.y + '%'
                    }
                },
                groupPadding: 0.25,
                pointPadding: 0.15,
            }
            return chartConfig
        },

        filterEmpty() {
            return Object.values(this.filters).every(x => x === null || x === '' || x === undefined || x.included.length === 0)
        },

        demoFilterEmpty() {
            return this.$_.isArray(this.demoFilters) ? this.demoFilters.length === 0 : Object.keys(this.demoFilters).length === 0
        },

        noFilterResults() {
            let filterNum = this.filterEmpty ? 0 : Object.values(this.filters)
                .filter(x => x !== undefined)
                .map(filter => filter.included.length)
                .reduce((a, b) => a + b, 0)

            let totalFilters = filterNum + (this.demoFilterEmpty ? 0 : this.$_isArray(this.demoFilters) ? this.demoFilters.length : Object.keys(this.demoFilters).length)
            let currentTabCount = this.tabData[this.activeTab.show].data?.length ? this.tabData[this.activeTab.show].data[0].data[0] : null

            return (totalFilters !== 0 && (currentTabCount === 0 || currentTabCount === null))
        }
    },

    mounted() {
        this.loadChartData()
    },

    methods: {
        prefsFetched(v) {
            let prefs = {}
            v.forEach(pref => {
                prefs[pref.code] = pref.user_value !== "0"
            })
            this.prefs.sisReportedConfigured = prefs['ATTENDANCE_EQUIV_RATE']
            this.caPageReload++
        },

        loadChartData() {
            this.tabData.district.data = this.tempData.slice(0, this.seriesNumToShow)
        },

        clearFilter(filter) {
        },

        clearAllFilters() {
        },

        apply() {
            this.seriesNumToShow = this.selectedTimePeriod.id === '0' ? 1 : this.selectedTimePeriod.id === '-2' ? 2 : 5
            this.loadChartData()
        },

        applyMore() {

        },

        tabClicked(tab) {
            this.activeTab = tab
        },

        chartParamChange(type, value) {
            switch(type) {
                case 'timePeriod':
                    this.selectedTimePeriod = value
                    this.apply()
                    break;
                case 'countToggle':
                    this.countsToggle = value
                    break;
                default:
            }
        }
    }
}
</script>

<style lang="scss" scoped>

.ca-tabs {
    padding-left: 18px;
    height: 70px !important;

    ::v-deep .fe-tabs-default {
        padding-left: 0 !important
    }
    ::v-deep .fe-tabs a {
        padding-bottom: 15px;
    }
    ::v-deep .fe-tabs .v-tabs-slider {
        width: 100% !important;
        left: 0 !important;
    }
    ::v-deep .fe-tabs-default .v-tab {
        font-size: 14px;
    }
    ::v-deep .fe-tabs-default-color a {
        color: #495B8F !important;
        font-weight: 500 !important;

        &:hover {
            span {
                opacity: 1 !important;
            }
        }
    }
    ::v-deep .fe-tabs-default .v-tab:hover {
        background-color: #ECEDF1;
    }
    ::v-deep .fe-tabs-default .v-tab--active {
        color: #006C90 !important;
    }
    ::v-deep .fe-tabs-default .v-tab--active:hover {
        background-color: #E5F0F4;
    }
}
</style>

